<template>
  <v-main>
    <v-container align-left>
      <h3 class="mb-5 mt-5">Provider Detail</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div style="position:absolute;margin:0 auto;left:50%,right:50%;" v-if="loading">Loading...</div>
      <v-row>
        <h5>User Type: {{filterUserType(userData.user_type)}}</h5>
      </v-row>
      <v-row>
      <v-col>
          <label>Name</label>
          <hr/>
          <div class="name">{{userData.name}}</div>
      </v-col>
      <v-col>
          <label>Phone No</label>
          <hr/>
          <div class="name"><a href="">{{userData.phone}}</a></div>
      </v-col>
      <v-col>
          <label>Gender</label>
          <hr/>
          <div class="name">{{filterGender(userData.gender)}}</div>
      </v-col>
      <v-col>
          <label>Marital Status</label>
          <hr/>
          <div class="name">{{filterMaritalStatus(userData.marital_status)}}</div>
      </v-col>
      <v-col>
          <label>State/Region</label>
          <hr/>
          <div class="name">{{userData.state_region}}</div>
      </v-col>
      <v-col>
          <label>Register Date</label>
          <hr/>
          <div class="name">{{userData.createddate}}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
          <label>Provider Type</label>
          <hr/>
          <div class="name">{{userData.provider_type}}</div>
      </v-col>
      <v-col>
          <label>Organization</label>
          <hr/>
          <div class="name">{{userData.organization}}</div>
      </v-col>
    </v-row>
    <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Lecture
          </th>
          <th class="text-left">
            Progress
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in providerLectureProgress"
          :key="item.id"
        >
          <td>{{ item.lectureTitle }}</td>
          <td>
            <v-progress-circular
              :rotate="180"
              :size="100"
              :width="15"
              :value="item.progress"
              color="pink"
            >
            {{ item.progress }}
            </v-progress-circular>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Pre Test Score
          </th>
          <th class="text-left">
            Pre Test Attempt Date
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ providerData.preTestScore}}</td>
          <td>{{ providerData.preTestAttemptDate}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
             Post Test Score
          </th>
          <th class="text-left">
            Post Test Passed Date
          </th>
          <th class="text-left">
            Total Failed Post Test Count
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ providerData.postTestScore}}</td>
          <td>{{ providerData.postTestPassedDate}}</td>
          <td>{{ providerData.totalFailedPostTest}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Date
          </th>
          <th class="text-left">
            Progress Name
          </th>
          <th class="text-left">
            isDone
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in providerProgress"
          :key="item.id"
        >
          <td>{{ item.createdDate }}</td>
          <td>{{ item.taskName }}</td>
          <td>{{ item.isDone }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_PROVIDER_DETAIL } from "../store/actions.type";
export default {
  name: "ProviderDetail",
  data() {
    return {
      userData: {},
      providerData: {},
      favoriteList: [],
      providerProgress: [],
      providerLectureProgress: [],
      providerCertificate: "",
      loading: true,
      errored: false
    };
  },
  computed: {
  },
  mounted() {
    this.currentUserID = localStorage.getItem("selectedUser");
    var payload = {"userId" : this.currentUserID};
    this.$store
      .dispatch(FETCH_PROVIDER_DETAIL,payload)
      .then((data) => {
        this.loading = false;
        console.log(data);
        this.userData = data.userData;
        this.providerData = data.providerData;
        this.providerProgress = data.providerProgress;
        this.providerLectureProgress = data.providerLectureProgress;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  created() {},
  methods: {
    filterGender(gender) {
      var response = ""
      if(gender == "f"){
        response = "Female";
      }else if(gender == "m"){
        response = "Male";
      }else if(gender == "o"){
        response = "Other";
      }
      return response;
    },
    filterMaritalStatus(data) {
      var response = "";
      if(data == "single"){
        response = "Single";
      }else if(data == "married"){
        response = "Married";
      }
      return response;
    },
    filterUserType(data) {
      if(data == "N"){
        return "Normal User";
      }else if(data == "P") {
        return "Provider";
      }
    }
  },
};
</script>
